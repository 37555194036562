import React from 'react'
import Layout from '../components/Layout'
import SmallHeader from '../components/SmallHeader'
import Footer from '../components/Footer'
import terms from '../data/terms.json'

const TermsPage = () => (
  <Layout path="/terms" title="Terms of Service - Brentiv - Rental Software">
    <div className="legal-page terms-of-service-page">
      <SmallHeader title="Terms of Service" />

      <div className="row-spaced legal-page-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10">
              {terms.map((item, index) => (
                <div key={index} className="container-padded">
                  <h2 className="title">
                    {index + 1}. {item.title}
                  </h2>
                  {item.subtitle && (
                    <p dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                  )}
                  <ol>
                    {item.terms &&
                      item.terms.map((term, k) => (
                        <li key={k}>
                          <p dangerouslySetInnerHTML={{ __html: term }} />
                        </li>
                      ))}
                  </ol>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default TermsPage
